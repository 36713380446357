import React from "react";
import ReactMarkdown from "react-markdown";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../components/layout";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {dark} from 'react-syntax-highlighter/dist/esm/styles/prism'
import rehypeRaw from 'rehype-raw'

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }, status: { eq: "published" }) {
      strapiId
      title
      description
      content
      publishedAt
      author {
        name
        picture {
          childImageSharp {
            fixed(width: 64, height: 64) {
              src
            }
          }
        }
      }
    }
  }
`;

const Article = ({ data }) => {
  const article = data.strapiArticle;
  const reDate = /\d+-\d+-\d+/;
  const publishedDate = reDate.exec(article.publishedAt);
  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.image,
    article: true,
  };

  return (
    <Layout seo={seo}>
      <div>
        <div style={{ paddingTop: 60, paddingBottom: 40 }}>
          <div className="uk-container" style={{ maxWidth: 800 }}>
            <p
              className={"article-title-font"}
              style={{ margin: "0px 0px 40px 0px" }}
            >
              {article.title}
            </p>

            <hr style={{ margin: "0 0 40px 0" }} />

            <ReactMarkdown
              children={article.content}
              className="uk-text-default uk-text-secondary"
              rehypePlugins={[rehypeRaw]}
              components={{
                code({node, inline, className, children, ...props}) {
                  const match = /language-(\w+)/.exec(className || '')
                  return !inline && match ? (
                      <SyntaxHighlighter
                          children={String(children).replace(/\n$/, '')}
                          style={dark}
                          language={match[1]}
                          PreTag="div"
                          {...props}
                      />
                  ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                  )
                }
              }}
              escapeHtml={false}
              transformImageUri={(uri) =>
                uri.startsWith("http")
                  ? uri
                  : `${"https://web-dev.tappytoon.com:1337"}${uri}`
              }
            />

            <hr style={{ margin: "40px 0px" }} />

            <div className="uk-grid-small uk-flex-left" data-uk-grid="true">
              <div>
                {article.author.picture && (
                  <Img
                    fixed={article.author.picture.childImageSharp.fixed}
                    imgStyle={{
                      position: "static",
                      borderRadius: "50%",
                      width: 64,
                    }}
                  />
                )}
              </div>
              <div className="uk-width-expand" style={{ alignSelf: "center" }}>
                <p
                  className="uk-margin-remove-bottom author-name-font text-ellipsis"
                  style={{ maxWidth: 160 }}
                >
                  {article.author.name}
                </p>
                <div style={{ width: 4, height: 4 }} />
                <p className=" uk-margin-remove-top author-date-font">
                  {publishedDate}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: 1100,
            paddingRight: "16px",
            paddingLeft: "16px",
          }}
        >
          {/*TODO: need to delete after footer update*/}
          <hr />
        </div>
      </div>
    </Layout>
  );
};

export default Article;
